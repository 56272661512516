import axios from "axios";

const updateOptions = () => {
return {
  headers:{'x-api-key':'1O015YTC5GsJHrkCo7nc','userId':'c15965f7-5a90-4a54-b29e-f91b9ee528ca'}
}
};
export default async function (url) {
  const { data } = await axios.get(url, updateOptions());
  return data;
}
