import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA_DbRvFyRxnQ3bjjBlcGSmEXG3gVzdM5I",
  authDomain: "tradeplan-219520.firebaseapp.com",
  databaseURL: "https://tradeplan-219520.firebaseio.com",
  projectId: "tradeplan-219520",
  storageBucket: "tradeplan-219520.appspot.com",
  messagingSenderId: "252778115973",
  appId: "1:252778115973:web:a430656a570f735709caec",
  measurementId: "G-1C4ED4JQ6F",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
