import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../utils/init-firebase";
import axios from "axios";
import constants from "../helper/constants.json";

import fetcher from "../utils/fetcher";
import useSWR from "swr";

import AppContext from "../AppContext";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  confirmPasswordReset,
  HeaderService,
  SavedScreenerService,
} from "firebase/auth";

const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
  HeaderService: () => Promise,
  SavedScreenerService: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [headerservice, setheaderservice] = useState({
    "x-api-key": "1O015YTC5GsJHrkCo7nc",
    userId: "",
  });
  const [savedScreener, setsavedScreener] = useState([]);

  const UserContext = useContext(AppContext);

  async function SavedScreener(headers) {
    /*  console.log("hiiii")
    const { data, error } = useSWR(`https://api.tradeplan.io/api/v2/screeners`, fetcher);
    console.log("hello")
    console.log(data);*/
    /*try {
      const response = await axios.get(`${constants.SERVER_URL}/screeners`, {
        headers: headers,
      });
      if (response.status == 200) {
        setsavedScreener(response.data);
        // console.log(response.data)
      }
    } catch (error) {
      return error.response;
    }*/
  }

  async function LoginDB(email, displayName) {
    const body = { email: email, firebase_id: "-1" };
    try {
      const response = await axios.post(
        `${constants.SERVER_URL}/login`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": constants.API_KEY,
            Authorization: window.localStorage.getItem("jwt_access_token"),
          },
        }
      );
      if (response.status !== 200) {
      } else {
        setheaderservice({
          "x-api-key": "1O015YTC5GsJHrkCo7nc",
          userId: response.data.user_id,
        });
        // UserContext.setIpDetails(response.data.user_id);
        UserContext.setuserLogin(displayName);
        UserContext.setselectedScan("saved");
        /* await SavedScreener({
          "x-api-key": "1O015YTC5GsJHrkCo7nc",
          userId: response.data.user_id,
        });*/
        window.localStorage.setItem("user", JSON.stringify(response.data));
        window.localStorage.setItem("screen_count", 0);
      }

      return response;
    } catch (error) {
      return error.response;
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user != null) {
        //To connect bakend login
        LoginDB(user.email, user.displayName);
      }
      setCurrentUser(user ? user : null);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log("The user is", currentUser);
  }, [currentUser]);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      url: `http://localhost:3000/login`,
    });
  }

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  function logout() {
    localStorage.clear();
    window.localStorage.clear();
    window.localStorage.removeItem("user");
    setsavedScreener([]);
    UserContext.setuserLogin(null);
    UserContext.setselectedScan("popular");
    return signOut(auth);
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }
  function HeaderService() {
    return headerservice;
  }

  function SavedScreenerService() {
    // console.log("get type of object"+typeof(savedScreener))
    return savedScreener;
  }

  const value = {
    currentUser,
    signInWithGoogle,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    HeaderService,
    SavedScreenerService,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
